import get from 'lodash/get'
import moment from 'moment-timezone'

export const formatInitialValuesOnProjectType = (
  projectType = '',
  genericInitialValues = {},
) => {
  switch (projectType) {
    case 'Beauty Boxes':
      return formatBeautyBoxIV(genericInitialValues)
    case 'Experiential Marketing':
      return formatExperientialMarketingIV(genericInitialValues)
    case 'Gift Cards':
      return formatGiftCardIV(genericInitialValues)
    case 'Guest Facing Campaigns':
      return formatGuestFacingCampIV(genericInitialValues)
    case 'Influencer Marketing':
      return formatInfluencerMarketingIV(genericInitialValues)
    case 'In Store Marketing and Packaging':
      return formatInStoreMarketingIV(genericInitialValues)
    case 'Other':
      return formatOtherIV(genericInitialValues)
    case 'Target.com Editorial Refresh':
    case 'Target.com Editorial':
      return formatEditorialRefreshIV(genericInitialValues)
    case 'Target Media Network':
      return formatTargetMediaNetworkIV(genericInitialValues)
    case 'Weekly Ad':
      return formatWeeklyAddIV(genericInitialValues)
    case 'Roundel Client Files':
      return formatRoundelValue(genericInitialValues)
    case 'Roundel Final Files': {
      return formatRoundelValue(genericInitialValues)
    }
    default:
      return genericInitialValues
  }
}

export const formatRoundelValue = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  newInitialValues['channel'] = 'Roundel'
  return newInitialValues
}

export const formatBeautyBoxIV = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  const runDate = get(newInitialValues, 'run_date', moment())
  const year = moment(runDate).format('YYYY')
  newInitialValues['campaign_year'] = year
  return newInitialValues
}

export const formatExperientialMarketingIV = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  newInitialValues['campaign_year'] = moment().format('YYYY')
  return newInitialValues
}

export const formatGiftCardIV = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  const runDate = get(newInitialValues, 'run_date', new Date())
  newInitialValues['campaign_year'] = runDate
    ? moment(runDate).format('YYYY')
    : moment().format('YYYY')
  return newInitialValues
}

export const formatGuestFacingCampIV = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  return newInitialValues
}

export const formatInfluencerMarketingIV = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  newInitialValues['channel'] = `Cross-Channel`
  newInitialValues['channel_ISMP'] = `Cross-Channel`
  newInitialValues['campaign_year'] = moment().format('YYYY')
  return newInitialValues
}

export const formatInStoreMarketingIV = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  return newInitialValues
}

export const formatOtherIV = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  newInitialValues['campaign_year'] = moment().format('YYYY')
  return newInitialValues
}

export const formatEditorialRefreshIV = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  const runDate = get(newInitialValues, 'run_date', moment())
  const year = moment(runDate).format('YYYY')
  newInitialValues['campaign_year'] = year
  return newInitialValues
}

export const formatTargetMediaNetworkIV = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  newInitialValues['campaign_year'] = moment().format('YYYY')
  return newInitialValues
}

export const formatWeeklyAddIV = (genericInitialValues = {}) => {
  let newInitialValues = { ...genericInitialValues }
  const runDate = get(newInitialValues, 'run_date', moment())
  const year = moment(runDate).format('YYYY')
  newInitialValues['campaign_year'] = year
  return newInitialValues
}

export const deleteRoundelFormKeys = (myObj, array) => {
  let tempObj = { ...myObj }
  for (let index = 0; index < array.length; index++) {
    delete tempObj[array[index]]
  }
  return tempObj
}

export const generateBaseIntialValues = ({
  metadataList = [],
  projectIdType = 'text',
  projectIdInitialValue = '',
  projectInitiator = {},
  projectType = '',
}) =>
  metadataList.reduce((initialValues = {}, field = {}) => {
    if (field.metadataValue) {
      initialValues[field.metadataName] = field.metadataValue
      if (field.metadataType === 'contact' && projectType !== 'Roundel Final Files') {
        initialValues[field.metadataName] = [
          ...field.metadataValue,
          projectInitiator,
        ]
      }
      return initialValues
    }

    switch (field.metadataType) {
      case 'autocomplete':
        initialValues[field.metadataName] = ''
        return initialValues
      case 'contact':
        initialValues[field.metadataName] =
          projectType !== 'Roundel Final Files' ? [projectInitiator] : []
        return initialValues
      case 'date':
        // If project id is date, hydrate date based on prev form.
        if (projectIdType === 'date' && field.metadataName === 'run_date') {
          initialValues[field.metadataName] = projectIdInitialValue || null
        } else {
          initialValues[field.metadataName] = null
        }
        return initialValues
      //Default covers all the weird cases of TextField
      default:
        // Campaign year is tricky.
        // It can be derived from a Moment or text; It can also be blank.
        if (field.metadataName === 'campaign_year') {
          if (projectIdType === 'date') {
            initialValues[field.metadataName] = projectIdInitialValue
              ? projectIdInitialValue.format('YYYY')
              : moment().format('YYYY')
          } else if (parseInt(projectIdInitialValue)) {
            initialValues[field.metadataName] = projectIdInitialValue
          } else {
            initialValues[field.metadataName] = ''
          }
          // Need to check if text field is Project Initiator field to autofill.
        } else if (field.metadataName === 'project_initiator') {
          initialValues[field.metadataName] = projectInitiator.display_name
          return initialValues
          // Check if project_id field is text and hydrate
        } else if (
          projectIdType === 'text' &&
          field.metadataName === 'project_id'
        ) {
          initialValues[field.metadataName] = projectIdInitialValue
        } else {
          initialValues[field.metadataName] = ''
        }
        return initialValues
    }
  }, {})

export const validateIsRequired =
  (isRequired = false, descriptor = '') =>
  (value) => {
    const re = new RegExp('<|>|%')
    let error
    if (typeof value === 'string' && isRequired && value.trim() === '') {
      error = `${descriptor} cannot be empty!`
    }

    if (isRequired && (!value || (Array.isArray(value) && !value.length))) {
      error = 'Required field!'
    }

    if (value && re.test(value)) {
      error = '<, >, % are not allowed!'
    }

    return error
  }

export const isDisabled = (entries = {}) => {
  const { projectType = '', projectId = '' } = entries

  if (typeof projectId === 'string') {
    return projectType.trim() !== '' && projectId.trim() !== '' ? false : true
  }

  return projectType.trim() !== '' && projectId ? false : true
}

const main = {
  formatBeautyBoxIV,
  formatExperientialMarketingIV,
  formatGiftCardIV,
  formatGuestFacingCampIV,
  formatInfluencerMarketingIV,
  formatInStoreMarketingIV,
  formatOtherIV,
  formatEditorialRefreshIV,
  formatTargetMediaNetworkIV,
  formatWeeklyAddIV,
}

export default main
