import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import idx from 'idx'
import { withAuth } from '@praxis/component-auth'
import { showNotification } from '../store/notification/actionCreator'
import { promptNoAccessPage } from '../store/auth/actions'
import apiConfig from '../config/apiConfig'
import { isNonAssetHubAPI } from '../helpers/UrlHelper'
import { TEXT_ERROR_DEFAULT } from '../constants/notifications'
import firefly from '../analytics/firefly'
import Delayed from './DelayedRender'

/*

Interceptor adds useful handlers to axios requests and responses.

If the user has an accessToken, it is used as the value for the
Authorization header. This applies to all requests to .target.com and
.tgt domains. You can disable this per request by passing in this config:
{headers: {Authorization: false}}

If an axios response is an error, it is handled based on the error code:
- 401: prompt the user to sign in
- 403: notify the user that they don't have permission to do that
- Other: show the axios error message to the user

When the component unmounts, the interceptors are removed.

*/
export class Interceptor extends React.Component {
  static state = {
    authInterceptor: undefined,
    errorInterceptor: undefined,
  }

  componentDidMount() {
    this.addAuthInterceptor()
    this.addErrorInterceptor()
  }

  componentDidUpdate(prevState) {
    const { lanId } = this.props
    // Error interceptor will be added before props are mapped leaving lanId = ''
    if (prevState.lanId !== lanId) {
      this.addErrorInterceptor()
    }
  }

  componentWillUnmount() {
    this.removeAuthInterceptor()
    this.removeErrorInterceptor()
  }

  addAuthInterceptor = () => {
    const authInterceptor = axios.interceptors.request.use(
      (config) => {
        const requestURL = config.url
        if (!config.headers['Authorization']) {
          if (this.props.accessToken && this.isTargetDomain(config.url)) {
            config.headers.Authorization = this.props.accessToken
            if (config.url.indexOf('toss.target.com') !== -1) {
              config.headers.Authorization = ''
            }
          }
        }
        
        if(!config.headers['trace_id'] && this.isTargetDomain(requestURL) && !requestURL.includes('vault_digital_assets')) {
          config.headers['trace_id'] = uuidv4()
        }

        // These headers not required for Non AssetHub hosted URL's
        if (!isNonAssetHubAPI(requestURL)) {
          if (!config.headers['x-id-token']) {
            if (this.props.xIdToken && this.isTargetDomain(requestURL)) {
              config.headers['x-id-token'] = this.props.xIdToken
            }
          }
        }

        if (!config.headers['x-api-key']) {
          if (this.props.xApiKey && this.isTargetDomain(requestURL)) {
            config.headers['x-api-key'] = this.props.xApiKey
          }
        }

        if (config.url.includes('chunk_uploads')) {
          delete config.headers['x-api-key']
        }
        return config
      },
      (error) => Promise.reject(error)
    )
    this.setState({ authInterceptor })
  }

  removeAuthInterceptor = () => {
    axios.interceptors.request.eject(this.state.authInterceptor)
    this.setState({ authInterceptor: undefined })
  }

  addErrorInterceptor = () => {
    const {
      retryLimit = 2,
      apiForRetry = [],
      apiToIgnoreErrors = [],
      uploadRetryLimit = 5,
    } = apiConfig
    const {
      lanId,
      promptNoAccessPage = () => {},
      login = () => {},
    } = this.props
    const errorInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const requestUrl = idx(error, (_) => _.response.config.url)

          let apiExistsInRetryList =
            apiForRetry.some((obj = '') => {
              const retryAPIRegex = new RegExp('\\/' + obj + '(:?\\/|\\?)')
              return retryAPIRegex.test(requestUrl)
            }) || false

          let apiExistsIgnoreErrors =
            apiToIgnoreErrors.some((obj = '') => {
              const errorIgnoreRegex = new RegExp(obj + '$')
              return errorIgnoreRegex.test(requestUrl)
            }) || false

          const code = error.response.status
          if (code === 401) {
            login()
          } else if (code === 403) {
            promptNoAccessPage(error.response.data.key)
          } else if (
            code > 0 &&
            ((code !== 404 && code !== 400) || apiExistsIgnoreErrors)
          ) {
            let message = TEXT_ERROR_DEFAULT
            if (code === 500 || code === 501) {
              message = TEXT_ERROR_DEFAULT
            } else if (code === 409) {
              error.config.retryCount = retryLimit
              message = error.response.data.message
            } else if (error.message) {
              message = error.message
            }
            error.config.retryCount = error.config.retryCount || Number(0)
            if (
              apiExistsInRetryList &&
              (error.config.retryCount < retryLimit ||
                (apiExistsIgnoreErrors &&
                  error.config.retryCount < uploadRetryLimit))
            ) {
              error.config.retryCount++
              return axios.request(error.config)
            } else if (!apiExistsIgnoreErrors) {
              this.props.showNotification(true, message, 'error')
            }
          }

          if (lanId) {
            firefly.trackApiError(error.response, lanId)
          }
        }
        return Promise.reject(error)
      }
    )
    this.setState({ errorInterceptor })
  }

  removeErrorInterceptor = () => {
    axios.interceptors.request.eject(this.state.errorInterceptor)
    this.setState({ errorInterceptor: undefined })
  }

  // hostname ends with .target.com or .tgt
  isTargetDomain = (url) =>
    /^([^/]+:)?\/{2,3}[^/]+?(\.target\.com|\.tgt)(:|\/|$)/i.test(url)

  render() {
    const { session = {}, children } = this.props
    const { userInfo = {} } = session || {}
    const { accessToken = '' } = userInfo
    const waitTime = accessToken ? 0 : 2000
    return <Delayed waitBeforeShow={waitTime}>{children}</Delayed>
  }
}

Interceptor.propTypes = {
  accessToken: PropTypes.string,
  lanId: PropTypes.string,
  promptToSignIn: PropTypes.func,
  showNotification: PropTypes.func,
  promptToNoAccess: PropTypes.func,
  xApiKey: PropTypes.string,
  xIdToken: PropTypes.string,
}

const mapStateToProps = (state = {}) => {
  const { auth = {} } = state
  const { lanId = '', xIdToken = '' } = auth
  return {
    accessToken: auth.accessToken,
    xApiKey: apiConfig.key,
    lanId: lanId,
    xIdToken: xIdToken,
  }
}

const mapDispatchToProps = {
  // promptToSignIn,
  showNotification,
  promptNoAccessPage,
  // promptToNoAccess,
  // signOut,
}

const mapAuthToProps = (auth) => ({
  ...auth,
})

export default withAuth(mapAuthToProps)(
  connect(mapStateToProps, mapDispatchToProps)(Interceptor)
)
