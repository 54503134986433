import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Card, Avatar, Grow } from '@mui/material'
import { Lock, DeviceHub } from '@mui/icons-material'
import withRouter from '../../containers/Router/WithRouter'
import NoProjectImage from '../../images/NoProjectImage.svg?url'
import apiConfig from '../../config/apiConfig'
import RemoteImageLoader from '../RemoteImageLoader'
import CustomCard from '../Common/Card/CustomCard'

const styles = makeStyles((theme) => ({
  card: {
    width: 450,
    margin: 5,
    display: 'flex',
    flexFlow: 'column wrap',
  },
  cardContent: {
    display: 'inline-flex',
    width: '100%',
    color: '#212121',
    fontSize: '14px',
    backgroundColor: 'rgba(239, 239, 239, 0.498039215686275)',
  },
  cardContentBottom: {
    height: 'auto',
    margin: 'auto 0 0',
  },
  cardContentContainer: {
    padding: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentContainerChild: {
    width: '50%',
  },
  cardFooterLeft: {
    float: 'left',
  },
  cardFooterRight: {
    float: 'right',
    [useTheme().breakpoints.only('xs')]: {
      float: 'left',
    },
  },
  cardHeaderLinked: {
    color: '#0D46A0',
    backgroundColor: 'rgba(239, 239, 239, 0.5)',
    cursor: 'pointer',
  },
  media: {
    minHeight: '270px',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 16,
    color: '#0D46A0',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tcin: {
    height: '350px',
  },
  avatar: {
    backgroundColor: '#FFF',
  },
  avatarIcon: {
    color: '#0D46A0',
  },
  cardLabelRight: {
    display: 'inline-block',
    width: 96,
    color: '#8D8D8D',
  },
  cardLabelLeft: {
    display: 'inline-block',
    width: 78,
    color: '#8D8D8D',
  },
  tcinCycle: {
    marginRight: '65px',
    color: '#8D8D8D',
  },
  headerTitle: {
    fontSize: '20px',
    color: '#0D46A0',
    textTransform: 'capitalize',
  },
  subheaderTitle: {
    fontSize: '15px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  editIcon: {
    marginRight: '20px',
    color: '#717171',
  },
  colorTextSecondary: {
    color: '#484848',
    fontWeight: '300',
    fontSize: 15,
  },
  cardHeader: {
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: '#efefef',
  },
}))

export class GridCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      shadow: 1,
    }
  }

  loadHeroImage = (assetArray) => {
    let heroImg = NoProjectImage
    if (
      typeof assetArray === 'object' &&
      assetArray.length &&
      assetArray[0] !== null
    ) {
      heroImg = `${assetArray[0]}`
    }
    return heroImg
  }

  formatDate = (dateStr) => {
    let resDate = null
    if (dateStr) {
      resDate = dateStr.slice(0, 10)
      resDate = resDate.split('-')
      resDate = resDate[1] + '/' + resDate[2] + '/' + resDate[0]
    }
    return resDate
  }

  handleHeaderClick = () => {
    const {
      project: { project_uuid = '' },
    } = this.props
    const {
      projects: { trackerUrl = '' },
    } = apiConfig
    this.props?.router?.navigate(`${trackerUrl}/${project_uuid}/dashboard`)
  }

  renderCardFooterContent = (project = {}) => {
    const { classes } = this.props
    return (
      <div className={cx(classes.cardContent)}>
        <div className={classes.cardContentContainer}>
          <div className={classes.contentContainerChild}>
            <div style={{ display: 'inline-block', float: 'left' }}>
              <div>
                <span>{'ID: '}</span>
                {project?.project_id}
              </div>
              <div>
                <div className={classes.cardLabelLeft}>{'Campaign: '}</div>
                {project?.campaign ? (
                  project?.campaign
                ) : (
                  <span className={classes.cardLabel + ' no-campaign'}>
                    {'N/A'}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={classes.contentContainerChild}>
            <div style={{ display: 'inline-block', float: 'right' }}>
              <div>
                <div className={classes.cardLabelRight}>{'Due Date: '}</div>
                {this.formatDate(project?.due_date)}
              </div>
              <div>
                <div className={classes.cardLabelRight}>{'Run / Launch: '}</div>
                {this.formatDate(project?.run_date)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getCardConfigs = (project = {}) => {
    const { classes = {} } = this.props
    return {
      cardHeader: {
        headerAvatar: (
          <Avatar aria-label="Recipe" className={classes.avatar}>
            {project?.confidential ? (
              <Lock className={classes.avatarIcon} />
            ) : (
              <DeviceHub className={classes.avatarIcon} />
            )}
          </Avatar>
        ),
        headerActionContent: () => {},
        title: project?.project_name,
        subheader: project?.project_type,
        identifier: '',
      },
      renderCustomFooterContent: true,
      displayFooter: true,
    }
  }

  render() {
    const { classes = {}, project = {} } = this.props
    const cardConfig = this.getCardConfigs(project)
    if (!project || !Object.keys(project).length) {
      return (
        <Grow in>
          <Card className={classes.card}>No project data found</Card>
        </Grow>
      )
    }

    let heroImg = this.loadHeroImage(project?.project_assets)
    return (
      <CustomCard
        minWidth={390}
        width={390}
        cardConfig={cardConfig}
        cardContent={(() => (
          <>
            <div className={classes.media}>
              <RemoteImageLoader
                Src={heroImg || NoProjectImage}
                AltText={'asset'}
                imgStyle={{
                  maxWidth: '98%',
                  maxHeight: 180,
                }}
              />
            </div>
          </>
        ))()}
        footerContent={this.renderCardFooterContent(project)}
        dispatchClickAction={(props) => {
          this.handleHeaderClick()
        }}
      />
    )
  }
}

GridCard.contextTypes = {
  router: PropTypes.object.isRequired,
}

GridCard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  callBackEditProject: PropTypes.func,
  classes: PropTypes.object,
  project: PropTypes.object,
}

const MyComponent = (props) => {
  const classes = styles()
  return <GridCard {...props} classes={classes} />
}

export default withRouter(MyComponent)
