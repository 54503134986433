import React, { Fragment, useState, useEffect } from 'react'
import cx from 'classnames'
import { isEmpty, difference, uniq } from 'lodash'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  CircularProgress,
  Chip,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenIcon,
  Error as ErrorIcon,
} from '@mui/icons-material'

import { metadataDRMRegex } from '../../../helpers/Regexes'

import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import {
  ALLOWED_ACTION_KEYS,
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_SHOW_BUTTON_TEXT,
  METADATA_LOOKUP_DRM_ERROR,
  METADATA_LOOKUP_ERROR,
} from '../../constants/metadata'
import { formatCSTDate } from '../../utils/utils'
import {
  getAvailableChannelsInfo,
  getUsageInfoDetailsInfo,
} from '../../mappers/metadataMapper'
import { fetchDrmDetailsApi } from '../../store/apiService'

import { useMetadataContext } from './MetadataContext'

function UsageInfoCard({ containerClasses = '' }) {
  const {
    setIsMetadataUnsaved = () => {},
    updateMetadata = () => {},
    assetMetadata = {},
    isEditMode = false,
  } = useMetadataContext()

  const {
    metadata: { creative_usage_instructions = {}, usage_info = [] } = {},
  } = assetMetadata
  const {
    additional_directions = '',
    do_not_use = false,
    instructions: instructionsData = '',
  } = creative_usage_instructions || {}

  const [doNotUse, setDoNotUse] = useState(do_not_use ?? '')
  const [instructions, setInstructions] = useState(instructionsData)
  const [additionalDirections, setAdditionalDirections] = useState(
    additional_directions,
  )
  const [usageInformation, setUsageInformation] = useState(usage_info ?? [])
  const [expandedUsageRight, setExpandedUsageRight] = useState('')

  useEffect(() => {
    setIsMetadataUnsaved(false)
    if (
      doNotUse !== do_not_use ||
      instructions !== instructionsData ||
      additionalDirections !== additional_directions
    ) {
      updateMetadata('creative_usage_rights', {
        do_not_use: doNotUse,
        instructions,
        additional_directions: additionalDirections,
      })
      setIsMetadataUnsaved(true)
    }
  }, [doNotUse, instructions, additionalDirections])

  useEffect(() => {
    const modifiedDrms = usageInformation
      ?.map((a) => a?.drm_id)
      ?.filter((obj) => !isEmpty(obj))
    const existingDrms = usage_info
      ?.map((a) => a?.drm_id)
      ?.filter((obj) => !isEmpty(obj))
    const changedDrms = difference(modifiedDrms, existingDrms)
    if (changedDrms.length) {
      setIsMetadataUnsaved(true)
      modifiedDrms && updateMetadata('drm_ids', modifiedDrms)
    } else if (existingDrms?.length !== modifiedDrms?.length) {
      setIsMetadataUnsaved(true)
      modifiedDrms && updateMetadata('drm_ids', modifiedDrms)
    } else if (
      existingDrms?.length === modifiedDrms?.length &&
      uniq(modifiedDrms).length !== modifiedDrms.length
    ) {
      setIsMetadataUnsaved(true)
      modifiedDrms && updateMetadata('drm_ids', uniq(modifiedDrms))
    } else {
      setIsMetadataUnsaved(false)
    }
  }, [usageInformation])

  const addUsageRight = () => {
    // add new row
    const emptyRow = {
      drm_id: '',
      rights_holder_name: '',
      rights_holder_type: '',
      expiration_date: '',
      available_channels: '',
    }
    setUsageInformation(() => {
      const usageInfoTemp = [...usageInformation]
      usageInfoTemp.unshift(emptyRow)
      return usageInfoTemp
    })
    setIsMetadataUnsaved(true)
  }

  const handleAccordionToggle =
    (panelName = '') =>
    (event, expanded = false) => {
      setExpandedUsageRight(expanded ? panelName : false)
    }

  const deleteUsageRight = (identifier) => () => {
    setUsageInformation(() => {
      const usageInfoTemp = [...usageInformation]
      usageInfoTemp.splice(identifier, 1)
      return usageInfoTemp
    })
  }

  const handleChangeUsageRight = (identifier) => (event) => {
    // handle changes on the DRM ID input field
    const drmId = event.target.value || ''
    setUsageInformation(() => {
      let updatedRow = {}
      const usageInfoTemp = [...usageInformation]
      updatedRow['drm_id'] = drmId
      if (drmId === '') {
        updatedRow['isError'] = false
        updatedRow['isSystemError'] = false
      }
      usageInfoTemp[identifier] = Object.assign(
        {},
        { ...usageInfoTemp[identifier] },
        updatedRow,
      )
      return usageInfoTemp
    })
    setIsMetadataUnsaved(true)
  }

  const handleCreativeInstructions = (instructionValue) => {
    setInstructions(instructionValue)
    if (!instructionValue) {
      setAdditionalDirections('')
    }
  }

  const appendUsageRightsResponse = (usageRightsData = [], identifier = '') => {
    let updatedRow = {}
    if (usageRightsData.length) {
      const {
        channels = [],
        expiration_time = 0,
        holder_name: rights_holder_name = '',
        holder_type: rights_holder_type = '',
        internal: isInternalUsage = false,
        other_channel: isOtherChannelUsage = false,
      } = usageRightsData[0]
      const availableChannels = getAvailableChannelsInfo(
        channels,
        isInternalUsage,
        isOtherChannelUsage,
      )
      updatedRow = {
        rights_holder_name,
        rights_holder_type,
        expiration_date: expiration_time,
        available_channels: availableChannels,
        showProgress: false,
        isError: false,
        isSystemError: false,
      }
    } else {
      updatedRow = {
        showProgress: false,
        isError: true,
        isSystemError: false,
      }
    }
    setUsageInformation(() => {
      const usageInfoTemp = [...usageInformation]
      usageInfoTemp[identifier] = Object.assign(
        {},
        { ...usageInfoTemp[identifier] },
        updatedRow,
      )
      return usageInfoTemp
    })
  }

  const handleFetchUsageRight = (identifier) => async (event) => {
    if (ALLOWED_ACTION_KEYS.indexOf(event.keyCode) !== -1) {
      // fetch from URM API on tab out of DRM ID input field
      const drm_id = event.target.value || ''
      if (drm_id && metadataDRMRegex.test(drm_id)) {
        toggleLoader(identifier, true)
        const response = await fetchDrmDetailsApi(drm_id)
        if (response) {
          const { data = [] } = response
          appendUsageRightsResponse(data, identifier)
        }
      } else {
        setUsageInformation(() => {
          const usageInfoTemp = [...usageInformation]
          usageInfoTemp[identifier]['isError'] = true
          return usageInfoTemp
        })
      }
    }
    return false
  }

  const toggleLoader = (identifier, value = false) => {
    // show or hide loader in the row
    setUsageInformation(() => {
      const usageInfoTemp = [...usageInformation]
      usageInfoTemp[identifier]['showProgress'] = value
      return usageInfoTemp
    })
  }

  return (
    <CollapsibleCard
      cardTitle={'Usage Information'}
      hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
      showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
      // defaultCollapse={usageInfoCollapse}
      // collapseCallback={collapseCallback}
      fullWidthContent
      isActionMode={false}
      // disablePrimaryButton={!isEditMode}
      // primaryButtonText="SAVE CHANGES"
      // secondaryButtonText="CANCEL CHANGES"
      // primaryButtonHandler={saveChanges}
      // secondaryButtonHandler={discardChanges}
    >
      <div className={containerClasses.metadataCard}>
        <div className={containerClasses.metadataCardActionWrapper}>
          <Typography className={containerClasses.metadataSubHeading}>
            Creative Usage Instructions
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                data-cy={doNotUse ? 'doNotUseCheck' : 'useCheck'}
                checked={doNotUse}
                onChange={() => setDoNotUse(!doNotUse)}
                value={`${doNotUse}`}
                disabled={!isEditMode}
              />
            }
            label="Do Not Use"
          />
          {(isEditMode || instructions) && (
            <FormControl
              variant="standard"
              className={cx(
                containerClasses.formControl,
                containerClasses.fullWidth,
              )}
              disabled={!isEditMode}
            >
              <InputLabel htmlFor="urCreativeUI">
                Creative Usage Instructions
              </InputLabel>
              <Input
                id="urCreativeUI"
                value={instructions}
                onChange={(event) =>
                  handleCreativeInstructions(event?.target?.value)
                }
                fullWidth
                multiline
                disableUnderline={!isEditMode}
                inputProps={{ 'data-cy': 'usageInstructions' }}
              />
            </FormControl>
          )}
          {instructions && (
            <Fragment>
              <FormControl
                variant="standard"
                className={containerClasses.formControl}
                disabled={!isEditMode}
              >
                <InputLabel htmlFor="urAdditionalDirections" shrink>
                  Additional Directions
                </InputLabel>
                <div
                  id="urAdditionalDirections"
                  className={containerClasses.formStaticWrap}
                >
                  <IconButton
                    onClick={() => {
                      window.open(additionalDirections, '_blank')
                    }}
                    aria-label="Open in new tab"
                    disabled={!additionalDirections}
                    size="large"
                  >
                    <OpenIcon />
                  </IconButton>
                  <Input
                    value={additionalDirections}
                    onChange={(event) =>
                      setAdditionalDirections(event?.target?.value)
                    }
                    disableUnderline={!isEditMode}
                    inputProps={{ 'data-cy': 'additionalInstructions' }}
                  />
                </div>
              </FormControl>
            </Fragment>
          )}
        </div>
        <Divider className={containerClasses.metadataDivider} />
        <div className={containerClasses.metadataCardActionWrapper}>
          <Typography className={containerClasses.metadataSubHeading}>
            Usage Rights{' '}
          </Typography>
          {isEditMode && (
            <IconButton
              data-cy="addUsageRights"
              onClick={addUsageRight}
              aria-label="Add Row"
              disabled={!isEditMode}
              size="large"
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
        <Table className={containerClasses.metadataTable}>
          <TableBody>
            {usageInformation?.length > 0 ? (
              usageInformation?.map((usageInfoItem = {}, key) => {
                const {
                  expiration_date = '',
                  available_channels = '',
                  custom_usage_rights = false,
                  custom_usage_rights_language = '',
                  drm_id = '',
                  rights_holder_name = '',
                  rights_holder_type = '',
                  usage_rights_language = '',
                } = usageInfoItem
                const expirationDateFormatted =
                  formatCSTDate(expiration_date, 'll') || ''
                return (
                  <Accordion
                    className={containerClasses.usageRightsAccordion}
                    expanded={expandedUsageRight === `usage-right_${key}`}
                    onChange={handleAccordionToggle(`usage-right_${key}`)}
                    key={'usageInfoItem_' + key}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <TableRow
                        key={key}
                        className={containerClasses.metadataTableRow}
                      >
                        <TableCell
                          className={containerClasses.metadataTableCell}
                        >
                          <div
                            className={containerClasses.metadataTableCellAction}
                          >
                            {isEditMode && (
                              <Fragment>
                                {usageInfoItem.showProgress && (
                                  <CircularProgress
                                    className={containerClasses.progressSpinner}
                                  />
                                )}
                                {!usageInfoItem.showProgress &&
                                  !usageInfoItem.isError &&
                                  !usageInfoItem.isSystemError && (
                                    <div
                                      className={
                                        containerClasses.metadataTableCellActionBtn +
                                        ' actionBtnHover'
                                      }
                                      aria-label="Delete Row"
                                      onClick={deleteUsageRight(key)}
                                    >
                                      <DeleteIcon
                                        data-cy="deleteUsageRights"
                                        color="inherit"
                                      />
                                    </div>
                                  )}
                                {!usageInfoItem.showProgress &&
                                  (usageInfoItem.isError ||
                                    usageInfoItem.isSystemError) && (
                                    <span className="error">
                                      <ErrorIcon />
                                    </span>
                                  )}
                              </Fragment>
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          className={containerClasses.metadataTableCell}
                        >
                          <FormControl
                            variant="standard"
                            className={containerClasses.formControl}
                            disabled={!isEditMode}
                            error={
                              usageInfoItem.isError ||
                              usageInfoItem.isSystemError
                            }
                          >
                            <InputLabel htmlFor={`urDrmId${key}`}>
                              DRM ID
                            </InputLabel>
                            <Input
                              data-cy="urDrmId"
                              id={`urDrmId${key}`}
                              idx={key}
                              onChange={handleChangeUsageRight(key)}
                              onKeyDown={handleFetchUsageRight(key)}
                              value={drm_id}
                            />
                            {usageInfoItem.isError && (
                              <FormHelperText
                                className={containerClasses.errorStyle}
                              >
                                {METADATA_LOOKUP_DRM_ERROR}
                              </FormHelperText>
                            )}
                            {usageInfoItem.isSystemError && (
                              <FormHelperText
                                className={containerClasses.errorStyle}
                              >
                                {METADATA_LOOKUP_ERROR}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </TableCell>
                        <TableCell
                          className={containerClasses.metadataTableCell}
                        >
                          {rights_holder_name && (
                            <FormControl
                              variant="standard"
                              className={containerClasses.formControl}
                              disabled
                            >
                              <InputLabel
                                className={containerClasses.usageSummaryLabel}
                                htmlFor={`urRightsHolder${key}`}
                                shrink
                              >
                                Rights Holder name
                              </InputLabel>
                              <div className={containerClasses.formStaticWrap}>
                                <span
                                  data-cy="urRightsHolder"
                                  id={`urRightsHolder${key}`}
                                >
                                  {rights_holder_name}
                                </span>
                              </div>
                            </FormControl>
                          )}
                          {!rights_holder_name && rights_holder_type && (
                            <FormControl
                              variant="standard"
                              className={containerClasses.formControl}
                              disabled
                            >
                              <InputLabel
                                className={containerClasses.usageSummaryLabel}
                                htmlFor={`urRightsHolderType${key}`}
                                shrink
                              >
                                Rights Holder Type
                              </InputLabel>
                              <div className={containerClasses.formStaticWrap}>
                                <span
                                  data-cy="urRightsHolderType"
                                  id={`urRightsHolderType${key}`}
                                >
                                  {rights_holder_type}
                                </span>
                              </div>
                            </FormControl>
                          )}
                        </TableCell>
                        <TableCell
                          className={containerClasses.metadataTableCell}
                        >
                          <FormControl
                            variant="standard"
                            className={containerClasses.formControl}
                            disabled
                          >
                            <InputLabel
                              className={containerClasses.usageSummaryLabel}
                              htmlFor={`urExpirationDate${key}`}
                              shrink
                            >
                              Expiration Date
                            </InputLabel>
                            <div className={containerClasses.formStaticWrap}>
                              <span
                                data-cy="urExpirationDate"
                                id={`urExpirationDate${key}`}
                              >
                                {expirationDateFormatted}
                              </span>
                            </div>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          className={containerClasses.metadataTableCell}
                        >
                          <FormControl
                            variant="standard"
                            className={containerClasses.formControl}
                            disabled
                          >
                            <InputLabel
                              className={containerClasses.usageSummaryLabel}
                              htmlFor={`urAvailableChannels${key}`}
                              shrink
                            >
                              Available Channels
                            </InputLabel>
                            <div className={containerClasses.formStaticWrap}>
                              <span
                                data-cy="urAvailableChannels"
                                id={`urAvailableChannels${key}`}
                              >
                                {available_channels}
                              </span>
                            </div>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    </AccordionSummary>
                    <Divider className={containerClasses.metadataDivider} />
                    <AccordionDetails>
                      <div className={containerClasses.usageDetails}>
                        {getUsageInfoDetailsInfo(usageInfoItem)?.length &&
                          getUsageInfoDetailsInfo(usageInfoItem).map(
                            (userInfoRow = {}, index) => {
                              const { label = '', value = '' } = userInfoRow
                              return (
                                <TableRow key={'usageInfoItem_' + index}>
                                  <TableCell
                                    className={
                                      containerClasses.usageDetailsLabel
                                    }
                                  >
                                    {label}
                                  </TableCell>
                                  <TableCell
                                    className={containerClasses.noBorderClass}
                                  >
                                    {' '}
                                    {value}
                                  </TableCell>
                                </TableRow>
                              )
                            },
                          )}
                        <TableRow>
                          <TableCell
                            className={containerClasses.usageDetailsLabel}
                          >
                            Usage Rights Language
                          </TableCell>
                        </TableRow>
                        <div className={containerClasses.usageDetailsText}>
                          {' '}
                          {custom_usage_rights
                            ? custom_usage_rights_language
                            : usage_rights_language}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )
              })
            ) : (
              <div className={containerClasses.noResults}>
                No Details Available
              </div>
            )}
          </TableBody>
        </Table>
      </div>
    </CollapsibleCard>
  )
}

export default UsageInfoCard
