import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import {
  setClassificationSelection,
  resetAssetSelection,
} from '../../store/classification/actionCreator'
import { withoutClassificationArray } from '../../constants/classification'
import { getSelectedClassification } from '../../helpers/classificationHelper'

const useStyles = makeStyles({
  containerLabel: {
    paddingLeft: '20px',
    paddingTop: '10px',
    maxHeight: 'fit-content',
  },
  formControl: {
    width: '100%',
  },
  classificationLabel: {
    padding: '5px 20px 0px',
    textTransform: 'uppercase',
  },
  assetTypeLabel: {
    paddingBottom: '5px',
    textTransform: 'uppercase',
  },
  formControlItem: {
    paddingLeft: '45px',
  },
})

function ClassificationTypeSelector({ isRoundelUser }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const classificationTypes =
    useSelector(
      ({ assetClassificationReducer: { classificationTypes = [] } }) =>
        classificationTypes,
    ) || []
  const [selectedClassication, setSelectedClassification] = useState('')
  const [selectedAssetTypeId, setSelectedAssetTypeId] = useState('')

  const classificationSelectorTypes = isRoundelUser
    ? classificationTypes
    : classificationTypes.concat(withoutClassificationArray)

  const handleClassificationChange = (event = {}) => {
    const {
      target: { value = '' },
    } = event
    setSelectedClassification(value)
    setSelectedAssetTypeId('')
    dispatch(resetAssetSelection())
  }

  const getClassificationDispatchObject = () => {
    let classificationObject = {
      selectedClassificationId: '',
      selectedClassification: selectedClassication,
      selectedAssetName: '',
      selectedDescription: '',
      selectedFolderPath: '',
    }
    const selectedClassificationObj = getSelectedClassification(
      classificationTypes,
      selectedClassication,
    )
    const assetTypeList = selectedClassificationObj?.['asset_types'] || []
    if (assetTypeList?.length) {
      const selectedAssetObj = assetTypeList.find(
        (assetDetail) => assetDetail.classification_id === selectedAssetTypeId,
      )
      if (selectedAssetObj) {
        classificationObject.selectedClassificationId = selectedAssetTypeId
        classificationObject.selectedAssetName = selectedAssetObj['name']
        classificationObject.selectedDescription =
          selectedAssetObj['description']
        classificationObject.selectedFolderPath =
          selectedAssetObj['folder_path']
      }
    }
    return classificationObject
  }

  const dispatchSelectedClassification = () => {
    const classificationObject = getClassificationDispatchObject()
    dispatch(setClassificationSelection(classificationObject))
  }

  const handleAssetTypeChange = (event) => {
    const {
      target: { value = '' },
    } = event
    setSelectedAssetTypeId(value)
  }

  const addTooltipToClassification = (classificationName = '') => (
    <Tooltip
      arrow
      title="Need an Asset Classification added to this list? Let us know by submitting a help form request (close this dialog and click the help icon in upper right corner)"
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            color: '#ed6c02',
            backgroundColor: 'white',
            border: '1px solid #ed6c02',
            fontSize: '14px',
            fontStyle: 'italic',
            maxWidth: '300px',
          },
        },
        arrow: {
          sx: {
            color: '#ed6c02',
          },
        },
      }}
    >
      {classificationName}
      <IconButton>
        <InfoOutlinedIcon color="warning" />
      </IconButton>
    </Tooltip>
  )

  useEffect(() => {
    dispatchSelectedClassification()
  }, [selectedAssetTypeId, selectedClassication])

  const filteredClassificationSelectorTypes = classificationSelectorTypes.map(
    (classificationDetail) => {
      if (classificationDetail.classification_name === 'Design File') {
        return {
          ...classificationDetail,
          asset_types: classificationDetail.asset_types.filter(
            (asset) =>
              asset.name !== 'Dieline' &&
              asset.name !== 'Icon' &&
              asset.name !== 'Template' &&
              asset.name !== 'Illustration',
          ),
        }
      } else if (
        classificationDetail.classification_name === 'Non-Product Image'
      ) {
        return {
          ...classificationDetail,
          asset_types: classificationDetail.asset_types.filter(
            (asset) => asset.name !== 'Set' && asset.name !== 'Events',
          ),
        }
      } else if (classificationDetail.classification_name === 'Product Image') {
        return {
          ...classificationDetail,
          asset_types: classificationDetail.asset_types.filter(
            (asset) => asset.name !== 'Infographic',
          ),
        }
      } else if (
        classificationDetail.classification_name === 'Non-Product Motion'
      ) {
        return {
          ...classificationDetail,
          asset_types: classificationDetail.asset_types.filter(
            (asset) =>
              asset.name !== 'Outline with talent' &&
              asset.name === 'Outline without talent' &&
              asset.name === 'Environment with talent' &&
              asset.name === 'Environment without talent',
          ),
        }
      } else if (
        classificationDetail.classification_name === 'Product Motion'
      ) {
        return {
          ...classificationDetail,
          asset_types: classificationDetail.asset_types.filter(
            (asset) =>
              asset.name !== 'Outline with talent' &&
              asset.name === 'Outline without talent' &&
              asset.name === 'Environment with talent' &&
              asset.name !== 'Environment without talent',
          ),
        }
      }
      return classificationDetail
    },
  )

  return (
    <>
      <div>
        <FormControl className={classes.formControl}>
          <FormLabel
            id="radio-buttons-group-label"
            className={classes.containerLabel}
          >
            Choose Asset Classification
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            defaultValue={
              classificationSelectorTypes.length
                ? classificationSelectorTypes[0].classification_name
                : ''
            }
            key="classification-radio-group"
            name="buttons-group"
            value={selectedClassication}
            onChange={handleClassificationChange}
            data-cy="classification-radio-group"
          >
            {filteredClassificationSelectorTypes.map(
              (classificationDetail, classificationIndex) => {
                const { classification_name = '', asset_types = [] } =
                  classificationDetail
                if (
                  asset_types.length === 0 &&
                  classification_name !== 'Upload without classification'
                ) {
                  return null
                }

                return (
                  <>
                    <FormControlLabel
                      value={classification_name}
                      control={<Radio />}
                      label={
                        classification_name === 'Upload without classification'
                          ? addTooltipToClassification(classification_name)
                          : classification_name
                      }
                      name={classification_name}
                      className={classes.classificationLabel}
                      key={classificationIndex}
                      data-cy={`classification-${classification_name}`}
                    />
                    <FormControl className={classes.formControlItem}>
                      <RadioGroup
                        aria-labelledby="radio-asset-type-label"
                        key={classification_name}
                        name={classification_name}
                        value={selectedAssetTypeId}
                        onChange={handleAssetTypeChange}
                        className={classes.assetTypeLabel}
                        data-cy={`assetType-${classification_name}`}
                      >
                        {selectedClassication === classification_name &&
                        asset_types.length ? (
                          asset_types.map((assetDetail, assetIndex) => {
                            const { name = '', classification_id = '' } =
                              assetDetail
                            return (
                              <FormControlLabel
                                value={classification_id}
                                control={<Radio />}
                                label={name}
                                name={name}
                                key={assetIndex}
                                data-cy={`assetType-${name}`}
                              />
                            )
                          })
                        ) : (
                          <></>
                        )}
                      </RadioGroup>
                    </FormControl>
                    <Divider />
                  </>
                )
              },
            )}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  )
}

export default ClassificationTypeSelector
