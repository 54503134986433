import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash'
import {
  assetsPayloadFromSearch,
  removeFilterValueFromExistingFilters,
} from '../../mappers/searchMapper'
import * as searchApi from '../apiService'
import {
  DEFAULT_ROWSPERPAGE,
  DEFAULT_SEARCH_TYPE,
  DEFAULT_SORT_BY_OPTION,
  searchDefaultFilters,
  SORT_BY_OPTIONS,
} from '../../constants/search'
import { trackSearchInsights } from '../../../analytics/betaSearch'
import { isEmptyObject } from '../../../helpers/UtilityHelper'

const initialState = {
  triggerSearchCall: false,
  filters: searchDefaultFilters,
  sortFields: SORT_BY_OPTIONS[DEFAULT_SORT_BY_OPTION],
  searchType: DEFAULT_SEARCH_TYPE,
  searchTerm: '',
  isExactMatch: false,
  page: Number(0),
  rowsPerPage: DEFAULT_ROWSPERPAGE,
  assetList: [],
  facets: {},
  channelFacets: {},
  pageLoading: false,
  totalResults: Number(0),
  selectedItems: [],
  reviewTemplates: {},
  templateId: '',
  previewImageRS: null,
  searchPayload: {},
  searchState: {},
}

export const searchAssetsList = createAsyncThunk(
  'search/searchAssetsList',
  async (payload, { getState }) => {
    const { auth = {} } = getState() || {}
    try {
      const response = await searchApi.searchAllAssets(payload)
      const { data: sampleResponse = {} } = response
      trackSearchInsights({
        auth,
        requestPayload: payload,
        response: sampleResponse,
      })
      return sampleResponse
    } catch (error) {
      trackSearchInsights({ auth, requestPayload: payload, response: error })
      return Promise.reject(error)
    }
  },
)

export const searchRSAssetsList = createAsyncThunk(
  'search/searchRSAssetsList',
  async (payload, { getState }) => {
    try {
      const response = await searchApi.searchAllAssetsByImage(payload)
      const { data: sampleResponse = {} } = response
      return sampleResponse
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const fetchSearchMetadata = createAsyncThunk(
  'search/fetchSearchMetadata',
  async () => {
    try {
      const response = await searchApi.fetchSearchMetadataApi()
      const { data: sampleResponse = {} } = response
      return sampleResponse
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const fetchReviewTemplates = createAsyncThunk(
  'search/fetchReviewTemplates',
  async () => {
    try {
      const response = await searchApi.callReviewTemplatesApi()
      const { data: reviewTemplates = {} } = response
      return reviewTemplates
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetFilters: (state, action) => {
      state = initialState
    },
    enableAssetLoader: (state) => {
      state.pageLoading = true
    },
    disableAssetLoader: (state) => {
      state.pageLoading = false
    },
    updateSearchTerm: (state, action) => {
      state.searchTerm = action.payload
      state.templateId = ''
      state.page = Number(0)
      state.triggerSearchCall = true
    },
    updateSearchTermWithType: (state, action) => {
      state.searchTerm = action.payload.searchTerm
      state.searchType = action.payload.searchType
      state.isExactMatch = action.payload.isExactMatch
      state.templateId = ''
      state.page = Number(0)
      state.triggerSearchCall = true
    },
    updateSearchPayload: (state, action) => {
      state.filters = { ...state.filters, ...action.payload.filters }
      state.templateId = ''
      state.page = Number(0)
      state.triggerSearchCall = true
    },
    removeFilterValue: (state, action) => {
      const filterToRemove = action.payload
      const stateFilters = { ...state.filters }
      const newFilters = removeFilterValueFromExistingFilters(
        stateFilters,
        filterToRemove,
      )
      state.filters = newFilters
      state.templateId = ''
      state.page = Number(0)
      state.triggerSearchCall = true
    },
    removeFilterFromPayload: (state, action) => {
      const filtersToRemove = action.payload
      const stateFilters = { ...state.filters }
      const newFilters = omit(stateFilters, filtersToRemove)
      state.filters = newFilters
      state.templateId = ''
      state.page = Number(0)
      state.triggerSearchCall = true
    },
    replaceFilters: (state, action) => {
      state.filters = action.payload.filters
      state.templateId = ''
      state.page = Number(0)
      state.triggerSearchCall = true
    },
    removeSearchTerm: (state, action) => {
      state.searchTerm = ''
      state.searchType = DEFAULT_SEARCH_TYPE
      state.templateId = ''
      state.page = Number(0)
      state.triggerSearchCall = true
    },
    updateSortFields: (state, action) => {
      state.sortFields = action.payload
      state.triggerSearchCall = true
    },
    updatePageNumber: (state, action) => {
      state.page = action.payload
      state.triggerSearchCall = true
    },
    updateRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
      state.page = Number(0)
      state.triggerSearchCall = true
    },
    refreshSearch: (state, action) => {
      state.filters = searchDefaultFilters
      state.templateId = ''
      state.page = Number(0)
      state.rowsPerPage = DEFAULT_ROWSPERPAGE
      state.searchTerm = ''
      state.searchType = DEFAULT_SEARCH_TYPE
      state.isExactMatch = false
      state.sortFields = SORT_BY_OPTIONS[DEFAULT_SORT_BY_OPTION]
      state.triggerSearchCall = true
      state.selectedItems = []
    },
    updateUserSavedFilters: (state, action) => {
      state.filters = action.payload.filters
      state.page = action.payload.page
      state.rowsPerPage = action.payload.rowsPerPage
      state.searchTerm = action.payload.searchTerm
      state.searchType = action.payload.searchType
      state.sortFields = action.payload.sortFields
      state.templateId = action.payload.templateId
      state.triggerSearchCall = true
    },
    enableTriggerSearchCall: (state) => {
      state.triggerSearchCall = true
    },
    clearAllAppliedFilters: (state, action) => {
      state.filters = searchDefaultFilters
      state.triggerSearchCall = true
      state.templateId = ''
      state.page = Number(0)
    },
    saveSelectedItems: (state, action) => {
      state.selectedItems = action?.payload?.selectedItems
    },
    saveSearchByTextPayload: (state, action) => {
      state.searchPayload = action?.payload?.searchPayload
    },
    saveSearchPersistState: (state, action) => {
      state.searchState = action?.payload?.searchState
    },
    savePreviewImage: (state, action) => {
      state.previewImageRS = action?.payload?.previewImageRS
    },
    saveTemplateId: (state, action) => {
      state.templateId = action?.payload
      state.searchTerm = ''
      state.searchType = DEFAULT_SEARCH_TYPE
      state.isExactMatch = false
      state.page = Number(0)
      state.triggerSearchCall = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchAssetsList.pending, (state) => {
      state.pageLoading = true
    })
    builder.addCase(searchAssetsList.fulfilled, (state, action) => {
      const {
        payload: {
          filters = {},
          facets = {},
          search = [],
          total_results = Number(1),
        } = {},
        meta: {
          arg: {
            page = Number(0),
            rowsPerPage = DEFAULT_ROWSPERPAGE,
            payload: { search_term = '' },
          } = {},
        } = {},
      } = action
      state.searchTerm = search_term
      const searchList = assetsPayloadFromSearch(search)
      state.filters = filters
      state.page = page
      state.rowsPerPage = rowsPerPage
      state.pageLoading = false
      state.facets = facets
      state.assetList = searchList
      state.totalResults = total_results
      state.triggerSearchCall = false
    })
    builder.addCase(searchAssetsList.rejected, (state, action) => {
      state.pageLoading = false
      state.totalResults = 0
      state.assetList = []
      state.triggerSearchCall = false
    })
    builder.addCase(searchRSAssetsList.pending, (state) => {
      state.pageLoading = true
      state.totalResults = 0
      state.assetList = []
    })
    builder.addCase(searchRSAssetsList.fulfilled, (state, action) => {
      const { payload: { search = [], total_results = Number(1) } = {} } =
        action
      const searchList = assetsPayloadFromSearch(search)
      state.pageLoading = false
      state.assetList = searchList
      state.totalResults = total_results
      state.selectedItems = []
      state.triggerSearchCall = false
    })
    builder.addCase(searchRSAssetsList.rejected, (state, action) => {
      state.pageLoading = false
      state.triggerSearchCall = false
      state.selectedItems = []
    })
    builder.addCase(fetchReviewTemplates.fulfilled, (state, action) => {
      state.reviewTemplates = action?.payload
    })
    builder.addCase(fetchSearchMetadata.fulfilled, (state, action) => {
      const { payload = {} } = action
      const channelRecords = payload['channel'] || []
      const channelFacetPayload = { key: 'Channel', path: 'metadata.projectInfo.channel', records: channelRecords }
      state.channelFacets = channelFacetPayload
    })
  },
})

export default searchSlice.reducer
export const {
  updateSearchPayload,
  updateSearchTerm,
  updateSearchTermWithType,
  removeFilterFromPayload,
  replaceFilters,
  removeFilterValue,
  removeSearchTerm,
  clearAllAppliedFilters,
  saveSelectedItems,
  saveTemplateId,
  saveSearchByTextPayload,
  saveSearchPersistState,
  resetFilters,
  updateSortFields,
  updatePageNumber,
  updateRowsPerPage,
  refreshSearch,
  enableAssetLoader,
  disableAssetLoader,
  enableTriggerSearchCall,
  updateUserSavedFilters,
} = searchSlice.actions
