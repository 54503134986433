import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@mui/styles'
import styled from 'styled-components'
import {
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grow,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import LockIcon from '@mui/icons-material/Lock'
import MuiAccordion from '@mui/material/Accordion'

import Moment from 'moment'
import withRouter from '../../containers/Router/WithRouter'
import NoProjectImage from '../../images/NoProjectImage.svg?url'
import apiConfig from '../../config/apiConfig'
import RemoteImageLoader from '../RemoteImageLoader'

import { fetchProjectTasks } from '../../store/projects/actionCreator'
import { ROUNDEL_CLIENT_FILES, ROUNDEL_FINAL_FILES } from '../../constants/projects'

const styles = () => ({
  card: {
    minWidth: 450,
    margin: 5,
    display: 'flex',
    flexFlow: 'column wrap',
    border: '1px solid lightgray',
    background: '#FFFFFF',
  },
  headerTitle: {
    fontSize: '24px',
    color: '#0D46A0',
    textTransform: 'capitalize',
    paddingTop: 16,
    textAlign: 'left',
    cursor: 'pointer',
  },
  subheaderTitle: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#333333',
    paddingTop: 16,
    textAlign: 'left',
  },
  heading: {
    fontFamily: 'roboto',
    fontSize: '14px',
    color: '#0D46A0',
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  openText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#0D46A0',
    textTransform: 'capitalize',
    paddingRight: 8,
    fontWeight: 500,
    cursor: 'pointer',
  },
  borderBottomNone: {
    borderBottom: 'none',
  },
  padding16: {
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 16,
  },
  textRight: {
    textAlign: 'right',
  },
  confidentialText: {
    position: 'absolute',
    paddingTop: '8px',
    fontSize: '12px',
    fontFamily: 'Roboto',
    color: '#333333',
    fontWeight: 700,
  },
  infoHeading: {
    fontFamily: 'roboto',
    fontSize: '12px',
    color: '#333333',
    fontWeight: 700,
  },
  infoText: {
    fontFamily: 'roboto',
    fontSize: '12px',
    color: '#333333',
  },
  projectInfoHeading: {
    fontFamily: 'roboto',
    fontSize: '14px',
    color: '#333333',
    fontWeight: 700,
  },
  projectInfoText: {
    fontFamily: 'roboto',
    fontSize: '14px',
    color: '#333333',
  },
})
const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:is(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
})(MuiAccordion)

const IconLeftAccordionSummary = withStyles({
  expandIcon: {
    order: -1,
  },
  root: {
    marginTop: -10,
  },
})(AccordionSummary)

const Icon = styled((props) => (
  <div {...props}>
    <div className="d">
      <KeyboardArrowDownIcon />
    </div>
    <div className="u">
      <NavigateNextIcon />
    </div>
  </div>
))`
  & > .u {
    display: block;
  }
  & > .d {
    display: none;
  }
  .Mui-expanded & > .d {
    display: block;
  }
  .Mui-expanded & > .u {
    display: none;
  }
`

export class ListCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shadow: 1,
      isPreviousTaskExpanded: false,
    }
  }

  loadHeroImage = (assetArray) => {
    let heroImg = NoProjectImage
    if (
      typeof assetArray === 'object' &&
      assetArray.length &&
      assetArray[0] !== null
    ) {
      heroImg = `${assetArray[0]}`
    }
    return heroImg
  }

  handleHeaderClick = () => {
    this.props.router.navigate(
      `${apiConfig.projects.trackerUrl}/${this.props.project.project_uuid}/dashboard`,
    )
  }

  getTaskAssetList = (tasks = []) => {
    const { classes = {}, project = {} } = this.props
    const { review_job_response = [] } = project
    const { is_admin = false } = review_job_response
    const projectId = project.project_uuid
    let tasksList = []
    tasks &&
      tasks.forEach((task, index) => {
        tasksList.push(
          <TableRow key={index}>
            <TableCell
              className={classes.padding16 + ' ' + classes.borderBottomNone}
            >
              <span data-cy="currentTaskName" className={classes.infoText}>
                {' '}
                {task.job_name}{' '}
              </span>
            </TableCell>
            <TableCell
              className={classes.padding16 + ' ' + classes.borderBottomNone}
            >
              <span className={classes.infoHeading}> {'Due: '} </span>
              <span data-cy="currentTaskDueDate" className={classes.infoText}>
                {Moment.utc(task.due_date).format('MMMM DD, YYYY h:mm a')}
              </span>
            </TableCell>
            <TableCell
              className={classes.padding16 + ' ' + classes.borderBottomNone}
            >
              <span className={classes.infoHeading}>{'Role: '} </span>
              <span data-cy="currentTaskRole" className={classes.infoText}>
                {is_admin && 'Admin'} {task.role_name}
              </span>
            </TableCell>
            <TableCell
              className={
                classes.padding16 +
                ' ' +
                classes.textRight +
                ' ' +
                classes.borderBottomNone
              }
            >
              <span className={classes.infoHeading}> {'Status: '} </span>
              <span data-cy="currentTaskStatus" className={classes.infoText}>
                {' '}
                {task.status}{' '}
              </span>
            </TableCell>
            <TableCell
              className={
                classes.padding16 +
                ' ' +
                classes.textRight +
                ' ' +
                classes.borderBottomNone
              }
              onClick={() =>
                window.open(`/annotator/${projectId}/${task.job_id}`)
              }
            >
              <span className={classes.openText}>
                {' '}
                {'OPEN'}
                <OpenInNewIcon
                  style={{
                    height: '18px',
                    width: '18px',
                    color: '#0D46A0',
                    position: 'absolute',
                    marginTop: '-2px',
                  }}
                />
              </span>
            </TableCell>
          </TableRow>,
        )
      })
    return [...tasksList]
  }

  handleFetchTasks = (projectId = '') => {
    const { fetchProjectTasks, project = {} } = this.props
    const { review_job_response = [] } = project
    const { isPreviousTaskExpanded = false } = this.state
    this.setState({ isPreviousTaskExpanded: !isPreviousTaskExpanded })
    if (
      !isPreviousTaskExpanded &&
      review_job_response.length === 0 &&
      projectId
    ) {
      fetchProjectTasks(projectId)
    }
  }

  render() {
    const { classes = {}, project = {} } = this.props
    const {
      review_job_response = [],
      in_active_task_count: previousTasksCount = 0,
    } = project
    const projectId = project.project_uuid
    const {
      is_admin = false,
      admin_tasks = [],
      user_tasks = [],
    } = review_job_response
    const tasksList = is_admin ? admin_tasks : user_tasks
    let activeTasks = []
    let previousTasks = []

    if (tasksList) {
      tasksList.forEach((task) => {
        if (task.is_active) {
          activeTasks.push(task)
        } else {
          previousTasks.push(task)
        }
      })
    }

    if (!project || !Object.keys(project).length) {
      return (
        <Grow in>
          <Card className={classes.card}>No project data found</Card>
        </Grow>
      )
    }
    const activeTasksCount = activeTasks.length

    let heroImg = this.loadHeroImage(project.project_assets)
    return (
      <Grow in>
        <Card
          className={classes.card + ' ' + 'pContainer'}
          data-cy="projectListCard"
        >
          {project.confidential && (
            <div style={{ paddingLeft: 16, paddingTop: 16 }}>
              <span>
                <LockIcon
                  style={{
                    height: '24px',
                    width: '24px',
                    color: '#484848',
                    paddingRight: 5,
                  }}
                />
                <span className={classes.confidentialText}>
                  {' '}
                  Confidential
                  {project.project_release_date && (
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        color: '#333333',
                        fontWeight: 500,
                      }}
                    >
                      {' (Internal Release Date: '}
                      {Moment.utc(project.project_release_date).format(
                        'MMMM DD, YYYY',
                      )}
                      {')'}
                    </span>
                  )}
                </span>
              </span>
            </div>
          )}
          <Grid container spacing={0}>
            <Grid
              item
              lg
              xs
              sm
              md
              style={{
                paddingLeft: '16px',
                paddingTop: '16px',
                maxWidth: '100px',
                maxHeight: '100px',
              }}
            >
              <RemoteImageLoader
                Src={heroImg || NoProjectImage}
                AltText={'asset'}
              />
            </Grid>
            <Grid item lg={11} xs={8} sm={8} md={10}>
              <div className={classes.subheaderTitle}>
                {project.project_type}
              </div>
              <div
                data-cy="listCardTitle"
                className={classes.headerTitle}
                onClick={this.handleHeaderClick}
              >
                {' '}
                {project.project_name}{' '}
              </div>
              <div style={{ paddingTop: '16px', paddingBottom: '10px' }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={
                          classes.textCenter + ' ' + classes.borderBottomNone
                        }
                      >
                        <span className={classes.projectInfoHeading}>
                          {' '}
                          {'Campaign: '}{' '}
                        </span>
                        <span
                          data-cy="listCardCampaign"
                          className={classes.projectInfoText}
                        >
                          {project.campaign ? (
                            project.campaign
                          ) : (
                            <span
                              className={
                                classes.cardLabel + ' ' + 'no-campaign'
                              }
                            >
                              {'NA'}
                            </span>
                          )}
                        </span>
                      </TableCell>
                      <TableCell
                        className={
                          classes.textCenter + ' ' + classes.borderBottomNone
                        }
                      >
                        <span className={classes.projectInfoHeading}>
                        {project.project_type === ROUNDEL_CLIENT_FILES
                            ? 'Client Name: '
                            : project.project_type === ROUNDEL_FINAL_FILES
                              ? 'Salesforce ID:'
                              : 'Project ID: '}
                        </span>
                        <span
                          data-cy="listCardId"
                          className={classes.projectInfoText}
                        >
                          {project.project_id}
                        </span>
                      </TableCell>
                      <TableCell
                        className={
                          classes.textCenter + ' ' + classes.borderBottomNone
                        }
                      >
                        <span className={classes.projectInfoHeading}>
                          {' '}
                          {'Run Date: '}{' '}
                        </span>
                        <span
                          data-cy="projectRunDate"
                          className={classes.projectInfoText}
                        >
                          {' '}
                          {Moment.utc(project.run_date).format(
                            'MMMM DD, YYYY',
                          )}{' '}
                        </span>
                      </TableCell>
                      <TableCell
                        className={
                          classes.textCenter + ' ' + classes.borderBottomNone
                        }
                      >
                        <span className={classes.projectInfoHeading}>
                          {'Due Date: '}
                        </span>
                        <span
                          data-cy="projectDueDate"
                          className={classes.projectInfoText}
                        >
                          {' '}
                          {Moment.utc(project.due_date).format(
                            'MMMM DD, YYYY',
                          )}{' '}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Grid>
          </Grid>

          {activeTasksCount > 0 && (
            <Accordion
              data-cy="currentTasksAccordion"
              expanded={true}
              square
              style={{ background: '#F9F9F9' }}
            >
              <AccordionSummary style={{ cursor: 'default' }}>
                <Typography
                  className={classes.heading}
                  style={{ color: '#333333' }}
                >
                  CURRENT TASKS ({activeTasksCount})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableBody>{this.getTaskAssetList(activeTasks)}</TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          )}
          {previousTasksCount > 0 && (
            <Accordion square>
              <IconLeftAccordionSummary
                data-cy="previousTasksAccordion"
                expandIcon={
                  <Icon
                    style={{ color: '#0D46A0', height: '24px', width: '24px' }}
                  />
                }
                onClick={() => this.handleFetchTasks(projectId)}
              >
                <Typography className={classes.heading}>
                  {' '}
                  PREVIOUS TASKS ({previousTasksCount})
                </Typography>
              </IconLeftAccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableBody>{this.getTaskAssetList(previousTasks)}</TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          )}
        </Card>
      </Grow>
    )
  }
}

ListCard.contextTypes = {
  router: PropTypes.object.isRequired,
}

ListCard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  callBackEditProject: PropTypes.func,
  classes: PropTypes.object,
  fetchProjectTasks: PropTypes.func,
  project: PropTypes.object,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchProjectTasks }, dispatch)

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(ListCard)))
