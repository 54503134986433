import React, { useState, useEffect, startTransition } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { DEFAULT_GENAI_MODEL, availableAiModels } from '../../genai/constants/genai'
import { convertDateToDisplay } from '../../helpers/dateHelper'
import { createNewPrompt, fetchTenants, fetchPromptDetailsById, fetchSearchConfigWithDefaultFilters, updatePromptByPromptId } from '../services/promptService'
import PromptConditionsView from './PromptConditionsView'
import HeaderTitle from '../../components/Header/HeaderTitle'
import apiConfig from '../../config/apiConfig'
import { removeEmptyValueKeysFromObj } from '../../helpers/UtilityHelper'


const useStyles = makeStyles({
  promptHeading: {
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DDDDDD'
  },
  promptFormContainer: {
    height: '80vh',
    overflow: 'scroll'
  },
  basicPromptContainer: {
    width: '50%'
  },
  conditionListContainer: {
    padding: '20px 0px',
  },
  promptFieldsClass: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '30px',
  },
  buttonGroup: {
    display: 'flex',
    gap: '20px',
    padding: '0px 30px',
    justifyContent: 'end'
  },
  saveOrEditButton: {
    color: 'white',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
    '&:disabled': {
      backgroundColor: '#e3dddd78',
    }
  },
  historyStyles: {
    margin: '20px 0px',
    border: '1px solid #DDDDDD',
    width: 'calc(100% - 40px)',
  },
  historyRowClass: {
    maxHeight: '40px'
  },
  versionHeaderClass: {
    padding: '0px 10px',
  },
  versionClass: {
    padding: '0px 10px',
    color: '#178295',
    cursor: 'pointer'
  },
  additionalItemStylesWithBorder: {
    borderRight: '1px solid #DDDDDD',
    padding: '5px 10px'
  },
  additionalItemStyles: {
    padding: '5px 10px'
  },
  infoLabel: {
    color: '#666666',
    fontSize: '14px',
    paddingBottom: '5px',
  },
  historyLabel: {
    padding: '5px',
    borderBottom: '1px solid #DDDDDD'
  },
  tableBodyClasses: {
    maxHeight: '250px',
    overflowY: 'scroll',
  }
});

const PromptDetailsForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { promptId = '' } = useParams()
  const { displayName = '' } = useSelector(state => state?.auth || {})
  const { enterpriseUploads: { tenantId: assethubTenantId = '' } = {} } = apiConfig
  const {
    state: { categoryList = [], selectedTab = '' } = {}
  } = useLocation()
  const { category_id: otherCategoryId = '' } = categoryList.find((category) => category.name === 'OTHERS') || {}
  const [allVersionsPromptData, setAllVersionsPromptData] = useState([])
  const [tenantList, setTenantList] = useState([])
  const [mode, setMode] = useState('add')
  const [partnerName, setPartnerName] = useState('')
  const [name, setName] = useState('')
  const [model, setModel] = useState(DEFAULT_GENAI_MODEL)
  const [tenant, setTenant] = useState('')
  const [category, setCategory] = useState(otherCategoryId)
  const [context, setContext] = useState('')
  const [prompt, setPrompt] = useState('')
  const [selectedDataIndex, setSelectedDataIndex] = useState(0)
  const [searchFacets, setSearchFacets] = useState([])
  const [appliedConditions, setAppliedConditions] = useState({})

  useEffect(() => {
    getAllTenants()
    getSearchFilters()
  }, [])

  const getSearchFilters = () => {
    fetchSearchConfigWithDefaultFilters().then((response) => {
      const { facets: { facetResponse = [] } = {} } = response.data
      setSearchFacets(facetResponse)
    }).catch(() => setSearchFacets([]))
  }

  const getAllTenants = () => {
    fetchTenants().then((response) => {
      setTenantList(response.data)
    }).catch((error) => {
      setTenantList([])
    })
  }

  useEffect(() => {
    promptId && fetchPromptDetailsById(promptId).then((response) => {
      const allVersionsPromptData = response.data
      const {
        team_name = '',
        name = '',
        model_used = DEFAULT_GENAI_MODEL,
        category_id = '',
        context = '',
        prompt = '',
        tenant_id = '',
        conditions = null
      } = allVersionsPromptData.find((promptData) => promptData.status === 'ACTIVE') || {}

      startTransition(() => {
        setAllVersionsPromptData(allVersionsPromptData)
        setMode('view')
        setPartnerName(team_name)
        setName(name)
        setModel(model_used)
        setCategory(category_id)
        setContext(context)
        setPrompt(prompt)
        setTenant(tenant_id)
        setAppliedConditions(removeEmptyValueKeysFromObj(conditions))
      })
    })
  }, promptId)

  useEffect(() => {
    if (mode !== 'add') {
      const {
        name = '',
        model_used = DEFAULT_GENAI_MODEL,
        category_id = '',
        context = '',
        prompt = '',
        team_name = '',
        tenant_id = '',
        conditions = null
      } = allVersionsPromptData.sort((a, b) => b.version - a.version)[selectedDataIndex] || {}

      startTransition(() => {
        setName(name)
        setModel(model_used)
        setCategory(category_id)
        setContext(context)
        setPrompt(prompt)
        setTenant(tenant_id)
        setPartnerName(team_name)
        setAppliedConditions(removeEmptyValueKeysFromObj(conditions))
      })
    }
  }, [selectedDataIndex])

  const onSave = () => {
    // Save the prompt based on the mode
    let promptPayload = {
      team_name: partnerName,
      name,
      model_used: model,
      tenant_id: tenant,
      category_id: category,
      context,
      prompt,
      conditions: removeEmptyValueKeysFromObj(appliedConditions),
      created_by: displayName,
    }
    if (mode === 'add') {
      createNewPrompt(promptPayload).then((response) => {
        goBackToListPage()
      })
    } else {
      promptPayload['prompt_grouping_id'] = promptId
      updatePromptByPromptId(promptPayload).then((response) => {
        goBackToListPage()
      })
    }
  }

  const goBackToListPage = () => {
    navigate('/settings/prompts', { state: { selectedTab } })
  }

  const handleTenantFieldChange = (tenantId = '') => {
    const { tenant_name = '' } = tenantList.find((tenant) => tenant.tenant_id === tenantId) || {}
    startTransition(() => {
      setTenant(tenantId)
      setPartnerName(tenant_name)
      if (tenantId !== assethubTenantId) {
        setCategory(otherCategoryId)
      }
    })
  }

  const updateSelectedVersionIndex = (versionIndex) => {
    setSelectedDataIndex(versionIndex)
    setMode('view')
  }

  return (
    <>
      <HeaderTitle title={promptId ? `View Prompt: ${name}` : 'Add Prompt'} />
      <Grid container className={classes.promptFormContainer}>
        <Grid item className={classes.basicPromptContainer}>
          {(
            <Grid container className={classes.promptFieldsClass}>
              <Grid item>
                <FormControl size="small">
                  <InputLabel id="tenant-label">Tenant</InputLabel>
                  <Select
                    style={{ width: '400px', height: '50px' }}
                    labelId="tenant-label"
                    id="tenant-label"
                    value={tenant}
                    input={<OutlinedInput label="Tenant" />}
                    onChange={(event) => handleTenantFieldChange(event.target.value)}
                    disabled={mode !== 'add'}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {tenantList?.map((tenant) => (
                      <MenuItem value={tenant.tenant_id}>{tenant.tenant_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  required
                  id='partner'
                  label='Partner'
                  style={{ width: '400px', height: '50px' }}
                  data-testid='partner-input'
                  placeholder='Enter Partner Name'
                  InputLabelProps={{ shrink: true }}
                  value={partnerName}
                  fullWidth
                  onChange={(event) => setPartnerName(event.target.value)}
                  disabled={mode === 'view'}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  id='name'
                  label='Prompt Name'
                  style={{ width: '400px', height: '50px' }}
                  data-testid='name-input'
                  placeholder='Enter Prompt Name'
                  InputLabelProps={{ shrink: true }}
                  value={name}
                  fullWidth
                  onChange={(event) => setName(event.target.value)}
                  disabled={mode === 'view'}
                />
              </Grid>
              <Grid item>
                <FormControl size="small">
                  <InputLabel id="category-label">Category</InputLabel>
                  <Select
                    required
                    style={{ width: '400px', height: '50px' }}
                    labelId="category-label"
                    id="category-label"
                    value={category}
                    input={<OutlinedInput label="Category" />}
                    onChange={(event) => setCategory(event.target.value)}
                    disabled={mode === 'view' || tenant !== assethubTenantId}
                  >
                    {categoryList?.map((category) => (
                      <MenuItem value={category.category_id}>{category.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl size="small">
                  <InputLabel id="model-label">Model Used</InputLabel>
                  <Select
                    required
                    style={{ width: '400px', height: '50px' }}
                    labelId="model-label"
                    id="model-label"
                    value={model}
                    input={<OutlinedInput label="Model Used" />}
                    onChange={(event) => setModel(event.target.value)}
                    disabled={mode === 'view'}
                  >
                    {availableAiModels.map((category) => (
                      <MenuItem value={category}>{category}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: '600px' }}
                  required
                  id='context-name'
                  label='Context'
                  placeholder='Enter Context'
                  InputLabelProps={{ shrink: true }}
                  value={context}
                  fullWidth
                  multiline
                  minRows={4}
                  onChange={(event) => setContext(event.target.value)}
                  disabled={mode === 'view'}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  id="prompt-name"
                  label="Prompt"
                  placeholder="Enter Prompt"
                  style={{ width: '600px' }}
                  InputLabelProps={{ shrink: true }}
                  value={prompt}
                  fullWidth
                  multiline
                  minRows={6}
                  onChange={(event) => setPrompt(event.target.value)}
                  disabled={mode === 'view'}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item className={classes.basicPromptContainer}>
          <Grid item className={classes.conditionListContainer}>
            {searchFacets && <PromptConditionsView conditionsConfig={searchFacets} appliedConditions={appliedConditions} setAppliedConditions={setAppliedConditions} isDisabled={mode === 'view'} />}
          </Grid>
          {
            mode !== 'add' && (
              <Grid container className={classes.historyStyles}>
                <Grid item container className={classes.historyLabel}>
                  History
                </Grid>
                <Grid item container>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 250 }} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right" className={classes.versionHeaderClass}>Version</TableCell>
                          <TableCell align="right">Last Modified By</TableCell>
                          <TableCell align="right">Last Modified Date</TableCell>
                          {/* <TableCell align="right">Last Run Date</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBodyClasses}
                      >
                        {allVersionsPromptData.sort((a, b) => b.version - a.version).map((versionData, versionIndex) => {
                          const {
                            version = '',
                            created_by = '',
                            created_timestamp = '',
                            last_run_timestamp = ''
                          } = versionData
                          return (
                            <TableRow
                              key={version}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              selected={selectedDataIndex === versionIndex}
                              className={classes.historyRowClass}
                            >
                              <TableCell onClick={() => updateSelectedVersionIndex(versionIndex)} className={classes.versionClass}>
                                {version}
                              </TableCell>
                              <TableCell align="right">{<div>{created_by}</div>
                              }</TableCell>
                              <TableCell align="right">{convertDateToDisplay(created_timestamp)}</TableCell>
                              <TableCell align="right">{last_run_timestamp && convertDateToDisplay(last_run_timestamp)}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      <Box sx={{ padding: 2, borderTop: "1px solid #ddd", display: "flex", justifyContent: "flex-end", marginTop: "auto" }}>
        <Grid container className={classes.buttonGroup} >
          {
            selectedDataIndex === 0 && (mode === 'view' ? (
              <Button
                name='edit'
                variant="outlined"
                className={classes.saveOrEditButton}
                onClick={()=> setMode('edit')}
              >
                <EditIcon fontSize='small' />
                Edit
              </Button>
            ) : (
              <Button
                variant="outlined"
                className={classes.saveOrEditButton}
                onClick={onSave}
                disabled={
                  !(
                    prompt &&
                    category &&
                    model &&
                    context &&
                    name && 
                    partnerName
                  )
                }
              >
                Save
              </Button>
            )
            )
          }
          <Button
            variant="outlined"
            className={classes.transparentButton}
            onClick={goBackToListPage}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </>
  )
}

export default PromptDetailsForm
